
import DateUtil from 'Utils/dateUtil';

export default [
    // sagar add type selection colunm
    {
        type: "selection",
        width: 38,
        align: "center",
      },
    {
        title: 'Shift Name',
        key: 'ShiftName',
        sortable: true,
        minWidth: 100,
    },
	{
        title: 'Type',
        key: 'ShiftType',
		sortable: true,
        minWidth: 100,       
    },
    
    {
        title: 'Start Time',
        key: 'StartTimeF',
        sortable: true,
        minWidth: 100,       
    },
    {
        title: 'End Time',
        key: 'EndTimeF',
        sortable: true,
        minWidth: 100,       
    },
    {
        title: 'Week Off 1',
        key: 'off1Day',
        sortable: true,
        minWidth: 100,	
    },
    {
        title: 'Week Off 2',
        key: 'off2Day',
        sortable: true,
        minWidth: 100,	
    },
]
